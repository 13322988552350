import { useState } from "react";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import ClassicFormConsultation from "../Consultation-Classic";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import TagManager from "react-gtm-module";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";

const CRM_URL = "/api/contact";
const EMAIL_URL = "/api/contact/email";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId, darkLabels }) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submitMultiple } = useFetch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ClassicFormConsultation
        variant={variant}
        dispatchDestination={dispatchDestination}
        submitButton={submitButton}
        formId={formId}
        referralCode={referralCode}
        darkLabels={darkLabels}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ModalContactSuccess 
        visible={showModal} 
        onRequestClose={handleCloseModal}
      />
    </>
  );
};

export default withTheme(FormConsultation);

import { useState, useEffect, useContext } from "react";
import {
  FormContainer,
  FormItem,
  StyledInput,
  PButton,
  StyledTextArea,
  StyledSelect,
} from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Typography from "../../../components/Typography";
import { LocationContext } from "../../../context/LocationContext";
import { jsonData } from "../../../components/LocationsMap/_data";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  failed,
  referralCode,
  showModal,
  setShowModal,
  fieldsToIgnore = [],
}) {
  const [urlParam, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);

  // Filter locations from jsonData - include all non-dealer locations
  const showroomOptions = jsonData.features
    .filter(location => {
      // Skip dealer locations
      if (location.properties.dealer === 'yes') return false;
      // Ensure we have valid city and state
      return location.properties.city && location.properties.state;
    })
    .map(location => {
      const city = location.properties.city.trim();
      const state = location.properties.state.trim();
      return {
        value: `${city}, ${state}`,
        label: `${city}, ${state}`,
        data: {
          ...location,
          properties: {
            ...location.properties,
            city,
            state
          }
        }
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  // Watch for location changes and update showroom selection
  useEffect(() => {
    if (location && !selectedShowroom) {
      // Get user's actual location from localStorage
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
          // Set the user's actual location in the form
          form.setFieldsValue({
            city: userLocation.city || '',
            state: userLocation.state || ''
          });
        }
      } catch (error) {
        console.error('Error reading user location:', error);
      }

      // Get closest showroom from localStorage
      let closestShowroom = null;
      try {
        const storedShowroom = localStorage.getItem('locationFetched');
        if (storedShowroom) {
          closestShowroom = JSON.parse(storedShowroom);
          const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
          const matchingShowroom = showroomOptions.find(option => 
            option.value === showroomValue
          );

          if (matchingShowroom) {
            console.log('Setting initial showroom from location:', matchingShowroom.data);
            setSelectedShowroom(showroomValue);
            setShowroomData(matchingShowroom.data);
            
            form.setFieldsValue({
              showroom: showroomValue,
              tenantId: matchingShowroom.data.properties.tenantId
            });
          }
        }
      } catch (error) {
        console.error('Error reading closest showroom:', error);
      }
    }
  }, [location, form, selectedShowroom, showroomOptions]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          if (fieldsToIgnore.includes(entry[0])) continue;
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
      form.setFieldsValue({
        email: urlParams.get("email"),
      });
    }

    return () => {
      setUrlParams(null);
    };
  }, [fieldsToIgnore, form]);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId
      });
    }
  };

  const onFinish = async (values) => {
    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Get showroom location from selected option
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    const submissionData = {
      ...values,
      // User's actual location
      user_city: userLocation.city,
      user_state: userLocation.state,
      // Franchise/showroom location
      city: selectedOption.data.properties.city.trim(),
      state: selectedOption.data.properties.state,
      tenantId: selectedOption.data.properties.tenantId,
      selectedShowroom: values.showroom
    };

    console.log('Form values:', values);
    console.log('User location:', userLocation);
    console.log('Showroom location:', selectedOption.data.properties);
    console.log('Final submission data:', submissionData);

    handleSubmit(submissionData);
  };

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer
        name="small-form"
        size="small"
        onFinish={onFinish}
        form={form}
      >
        <FormItem
          validateTrigger={"onSubmit"}
          name={"full_name"}
          rules={[
            {
              required: true,
              message: "Full name required",
            },
          ]}
        >
          <StyledInput placeholder={"Full Name"} />
        </FormItem>
        <FormItem
          validateTrigger={"onBlur"}
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email required",
            },
            {
              type: "email",
              message: "Not a valid email",
            },
          ]}
        >
          <StyledInput placeholder={"Email address"} />
        </FormItem>
        <FormItem
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Phone required",
            },
          ]}
          normalize={(value) => {
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, "");
            if (onlyNums.length >= 4 && onlyNums.length <= 6) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
            }
            if (onlyNums.length > 6 && onlyNums.length <= 10) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
            }
            if (onlyNums.length > 10) {
              return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
            }
            return onlyNums;
          }}
        >
          <StyledInput placeholder={"Phone Number"} />
        </FormItem>
        <FormItem
          name="showroom"
          rules={[{ required: true, message: "Please select a showroom" }]}
          label="Nearest Showroom"
        >
          <StyledSelect
            placeholder="Select Showroom"
            options={showroomOptions}
            onChange={handleShowroomChange}
          />
        </FormItem>
        <FormItem name="tenantId" hidden>
          <StyledInput type="hidden" />
        </FormItem>
        <FormItem
          name={"message"}
          rules={[
            {
              required: false,
              message: "Comments",
            },
          ]}
        >
          <StyledTextArea placeholder={"Comments"} rows={3} />
        </FormItem>
        <FormItem initialValue={referralCode} name={"referral_code"}>
          <StyledInput placeholder={"Promotional Code"} $primary={true} />
        </FormItem>
        <div aria-hidden="true" tabIndex={"-1"} className="form-bot1">
          <FormItem name="username">
            <StyledInput placeholder="username" />
          </FormItem>
        </div>
        <FormItem>
          <PButton
            data-cy="submit"
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            role="link"
          />
        </FormItem>
      </FormContainer>
      <Typography
        margin={[0]}
        textAlign="center"
        color="negative"
        fontSize="14px"
      >
        {failed}
      </Typography>
      <Loading loading={loading} />
    </div>
  );
}
